<!-- 定义模版对象 -->
<template>
  <div class="collect" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
    <div class="collect-top">

      <div class="collect-title">
        <div class="collect-title-color"></div>
        <div class="collect-title-page font-18">{{ pagetitle }}</div>
      </div>
    </div>
    <div class="collect-bottom">
      <div class="collect-bottom-search">
        <el-form ref="form" :model="searchForm" label-width="55px" :inline="true">
          <el-form-item :label="type == 1 ? '店铺名' : '商品名'" label-position='left'>
            <el-input placeholder="请输入搜索条件" v-model="searchForm.name" style="width:240px">
              <i slot="suffix" class="collect-bottom-search-icon el-icon-search " @click="onSearch"></i>
            </el-input>
          </el-form-item>
          <el-form-item :label="null" label-position='left'>
            <el-button @click="cancelCollect" class="collect-bottom-search-btn">批量取消</el-button>
          </el-form-item>
        </el-form>

      </div>
      <div :class=" stickyFlag ? 'collect-bottom-table collect-bottom-table-sticky' : 'collect-bottom-table' " v-if="type==1">
        <el-table :data="tableData" key="shopTableData" @sort-change="sortByDate" ref='shopTableData' @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="title" label="店铺" width="300" align="center">
            <template slot-scope="scope">
              <div style="display: flex;text-align: left;padding: 11px 0;">
                <div style="width: 77px;height:77px;margin-right: 21px;">
                  <img style="width: 77px;height:77px;border-radius: 10px;" :src=" (scope.row.cover ? scope.row.cover  : require('../../assets/imgs/empty.png') ) | image(77,77)" alt="">
                </div>
                <div style="width: 420px;">
                  <div style="color:#013bfe;">
                    <!-- <span style="background-color: #e5f2fd;padding: 2px 5px;margin-right: 5px;">{{ scope.row.category }}</span> -->
                    <span class="font-10" @click="details()" style="line-height: 15px; color:#333;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{ scope.row.title }}</span>
                  </div>
                  <div style="margin-top: 6px;display:flex;height:28px;flex-wrap:wrap">

                    <span class="font-11" @click="shopid(scope.row.id)" style=" border: 1px solid #4974e96c;border-radius: 5px; background-color: #4974e91f;padding: 1px 9px;margin-right: 5px;color:#4975E9;cursor: pointer;min-width:80px"><i class="el-icon-link"></i> 店铺链接
                    </span>
                    <div v-if="scope.row.hostingMode && [1,2,'1','2'].includes(scope.row.hostingMode)" style="height:28px">
                      <el-image fit='contain' :src=" ( scope.row.hostingMode == 1 ? 'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/kjxp/tuoguan_all.png' : 'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/kjxp/tuoguan_half.png' ) | image(64,30) " style="width:64px;height:28px;border-radius:18px"></el-image>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" label="销售量">
            <el-table-column prop="shopSold" align="center" label="总销量" sortable='custom'>
              <template slot-scope="scope">
                <div style="width: 100%;font-weight: bold;">
                  {{  ( scope.row.shopSold || 0) | formatSales}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="dailySold" align="center" label="日销量" sortable='custom'>
              <template slot-scope="scope">
                <div style="width: 100%;font-weight: bold;">
                  {{   (scope.row.dailySold || 0) | formatSales}}
                </div>
                <div>
                  <span>
                    <i class="el-icon-caret-top" style="color:red" v-if="scope.row.weekSoldIncrease > 0"></i>
                    <i class="el-icon-caret-bottom" style="color:green" v-else></i>
                  </span>
                  {{ scope.row.dailySoldInerease || '0.00'  | formatPercent}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="weekSold" align="center" label="周销量" sortable='custom'>
              <template slot-scope="scope">
                <div style="width: 100%;font-weight: bold;">
                  {{   (scope.row.weekSold || 0) | formatSales}}
                </div>
                <div>
                  <span>
                    <i class="el-icon-caret-top" style="color:red" v-if="scope.row.weekSoldIncrease > 0"></i>
                    <i class="el-icon-caret-bottom" style="color:green" v-else></i>
                  </span>
                  {{ scope.row.weekSoldIncrease || '0.00'  | formatPercent}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="monthSold" align="center" label="月销量" sortable='custom'>
              <template slot-scope="scope">
                <div style="width: 100%;font-weight: bold;">
                  {{   (scope.row.monthSold || 0) | formatSales}}
                </div>
                <div>
                  <span>
                    <i class="el-icon-caret-top" style="color:red" v-if="scope.row.monthSoldIncrease > 0"></i>
                    <i class="el-icon-caret-bottom" style="color:green" v-else></i>
                  </span>
                  {{ scope.row.monthSoldIncrease || '0.00'  | formatPercent}}
                </div>
              </template>
            </el-table-column>

          </el-table-column>
          <el-table-column prop="shopSoldAmount" align="center" label="总销售额" sortable='custom'>
            <template slot-scope="scope">
              {{ scope.row.shopSoldAmount || '0.00'  | formatMoney}}
            </template>

          </el-table-column>

          <el-table-column prop="items" align="center" label="商品数量" width="120" sortable='custom'>
            <template slot-scope="scope">
              {{ scope.row.items || 0  | formatMoney}}
            </template>
          </el-table-column>
          <el-table-column prop="follower" align="center" label="粉丝数" width="120" sortable='custom'>
            <template slot-scope="scope">
              {{ scope.row.follower || 0  | formatMoney}}
            </template>
          </el-table-column>
          <el-table-column prop="shopOnlineTime" align="center" label="开店时间" width="130" sortable='custom'> </el-table-column>
          <el-table-column prop="avg" align="center" label="平均客单价" width="140" sortable='custom'>
            <template slot-scope="scope">
              <div style="width: 100%;text-align: center;">
                <div style="color:#f5762c;">{{scope.row.avg | formatMoney }} </div>
                <div class="font-10">{{scope.row.chAvg | formatMoney }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="score" align="center" label="评分" sortable='custom'>
            <template slot-scope="scope">
              <div style="font-weight: bold;">
                {{ scope.row.score ? scope.row.score  : '--' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="action" align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" @click="cancelCollectShop(scope.row)" class="collect-bottom-table-btn">取消收藏</el-button>
            </template>
          </el-table-column>
        </el-table>

      </div>
      <div :class=" stickyFlag ? 'collect-bottom-table collect-bottom-table-sticky' : 'collect-bottom-table' " v-if="type==2">
        <el-table :data="tableData" key="productTableData" :border='true' stripe @sort-change="sortByDate" ref='productTableData' @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="name" label="商品" width="350" align="center">
            <template slot-scope="scope">
              <div style="display: flex;text-align: left;">
                <div style="width: 77px;height:77px;margin-right: 21px;">
                  <el-popover placement="top" trigger="hover">
                    <div>
                      <img style="width: 150px;height:150px;border-radius: 10px;" :src=" (scope.row.imageUrl ? scope.row.imageUrl : require('../../assets/imgs/empty.png')) | image(150,150) " alt="">
                    </div>
                    <div class="same-pic-content-list-item-pic" slot="reference">
                      <img style="width: 77px;height:77px;border-radius: 10px;" :src=" (scope.row.imageUrl ? scope.row.imageUrl : require('../../assets/imgs/empty.png')) | image(77,77)" alt="">
                    </div>
                  </el-popover>
                </div>
                <div style="width:600px;">
                  <el-popover placement="top-start" width="350" trigger="hover" :content="scope.row.name">

                    <!-- <span style="background-color: #e5f2fd;padding: 2px 5px;margin-right: 5px;">{{ scope.row.category }}</span> -->
                    <span slot="reference" @click="spxq(scope.row.id)" class="font-10" style="line-height: 15px; color:#333;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;cursor: pointer;">
                      {{ scope.row.name || scope.row.productEnName || ''  }}
                    </span>

                  </el-popover>

                  <div style="margin-top: 6px;display:flex;align-items:center;height:28px;flex-wrap:wrap">

                    <span @click="spxq(scope.row.id)" class="font-11" style=" border: 1px solid #4974e96c;border-radius: 5px; background-color: #4974e91f;padding: 1px 9px;margin-right: 5px;color:#4975E9;cursor: pointer;min-width:80px"><i class="el-icon-link"></i>商品详情</span>
                    <div v-if="scope.row.hostingMode && [1,2,'1','2'].includes(scope.row.hostingMode)" style="height:28px">
                      <el-image fit='contain' :src=" ( scope.row.hostingMode == 1 ? 'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/kjxp/tuoguan_all.png' : 'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/kjxp/tuoguan_half.png' ) | image(64,30) " style="width:64px;height:28px;border-radius:18px"></el-image>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="secondCategory" label="品类" width="200" style="color:#333;font-weight: 400;">
            <template slot-scope="scope">
              <div style="padding: 7px 0;display: flex;justify-content:center;align-items:center">
                <el-tooltip class="item" effect="dark" :content="scope.row.secondCategory.join('>')" placement="top">
                  <span class="font-10" style="overflow: hidden;text-overflow: ellipsis; white-space: nowrap;box-sizing: border-box; line-height: 17px; cursor: pointer; background-color: #4974e91f;padding: 4px 6px;color:#4975E9;border-radius: 5px;border: 1px solid #4975E9;">{{ scope.row.secondCategory[scope.row.secondCategory.length-1] }}</span>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="goodsTrends" label="销量趋势" width="130">
            <template slot-scope="scope">
              <div v-if='scope.row.goodsTrends && scope.row.goodsTrends.length > 0'>
                <tendency :list="scope.row.goodsTrends" :id="scope.row.id"></tendency>
              </div>
              <div v-else>
                <i class="el-icon-warining"></i>收录中
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="price" align="center" label="价格" sortable='custom'>
            <template slot-scope="scope">
              <div style="width: 100%;">
                <div style="color:#E34D59;width: 100%;">{{scope.row.currencySymbol}}{{scope.row.price  | formatMoney}} </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="score" align="center" label="商品评分" sortable='custom'>
            <template slot-scope="scope">
              <div style="font-weight: bold;">
                {{ scope.row.score ? scope.row.score  : '--' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="销售量">
            <el-table-column prop="sales" align="center" label="总销量" sortable='custom'>
              <template slot-scope="scope">
                <div style="width: 100%;font-weight: bold;">
                  {{   scope.row.sales | formatSales}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="dailySold" align="center" label="日销量" sortable='custom'>
              <template slot-scope="scope">
                <div style="width: 100%;font-weight: bold;">
                  {{   (scope.row.dailySold || 0) | formatSales}}
                </div>
                <div>
                  <span>
                    <i class="el-icon-caret-top" style="color:red" v-if="scope.row.dailySoldInerease > 0"></i>
                    <i class="el-icon-caret-bottom" style="color:green" v-else></i>
                  </span>
                  {{ scope.row.dailySoldInerease || '0.00'  | formatPercent}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="weekSold" align="center" label="周销量" sortable='custom'>
              <template slot-scope="scope">
                <div style="width: 100%;font-weight: bold;">
                  {{   (scope.row.weekSold || 0) | formatSales}}
                </div>
                <div>
                  <span>
                    <i class="el-icon-caret-top" style="color:red" v-if="scope.row.weekSoldIncrease > 0"></i>
                    <i class="el-icon-caret-bottom" style="color:green" v-else></i>
                  </span>
                  {{ scope.row.weekSoldIncrease || '0.00'  | formatPercent}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="monthSold" align="center" label="月销量" sortable='custom'>
              <template slot-scope="scope">
                <div style="width: 100%;font-weight: bold;">
                  {{   (scope.row.monthSold || 0) | formatSales}}
                </div>
                <div>
                  <span>
                    <i class="el-icon-caret-top" style="color:red" v-if="scope.row.monthSoldIncrease > 0"></i>
                    <i class="el-icon-caret-bottom" style="color:green" v-else></i>
                  </span>
                  {{ scope.row.monthSoldIncrease || '0.00'  | formatPercent}}
                </div>
              </template>
            </el-table-column>

          </el-table-column>
          <el-table-column prop="onlineTime" align="center" label="市场年龄" sortable='custom'> </el-table-column>
          <el-table-column prop="action" align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" @click="cancelCollectProduct(scope.row)" class="collect-bottom-table-btn">取消收藏</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin: 10px auto;text-align: center;">
        <el-pagination @current-change="init" :current-page="searchForm.page" :page-size="searchForm.pageSize" layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="show_vip" width="20%" custom-class="dialogwidth" :show-close='true'>
      <div style="text-align:center;align-items:center;min-width:300px">
        <div>您还未开通会员，请购买开通</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toBuy">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showLogin" width="20%" custom-class="dialogwidth">
      <div style="text-align:center;align-items:center;min-width:300px">
        <div>您还未登录，请登录</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toLogin">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<!-- 定义JS变量 -->
<script>
import { getCollectProduct, listCollectShop, agetSaleAmtAndSumTrend, collectShop, collectProduct,getUserInfo  } from '../../api/api';
import tendency from '../../components/tendency'
export default {
  data() {
    return {
      pagetitle: '',//标题
      type: 1,// 类型 1 竞品 2竞店
      loading: false,
      show_vip: false,
      showLogin: false,
      user: {},
      tableData: [],
      searchForm: {
        name: '',
        page: 1,
        pageSize: 20,
        field: '',
        sort: '',
      },
      multipleSelection: [],
      total: 0,
      stickyFlag: false,
    }
  },
  created() {
    this.autoLogin();
  },
  components: {
    tendency
  },
  computed: {
    is_vip() {
      return this.$cookie.get('is_vip') == 'true' ? true : false
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    async autoLogin() {
      const { data: res } = await getUserInfo();
      if(res.code == '0000') {
        const { data } = res;
        if(data) {
          const { user, userBenefits } = data;
          if(user) {
            console.log('confirmLoginByMobile', res, data);
            this.user = user;
            let is_vip = false;
            if(userBenefits && userBenefits.length > 0) {
              userBenefits.map(v => {
                if(v && v.productCode && v.productCode == '1002') {
                  is_vip = v.isValid == 0 ? false : true;
                }
              })
            }
            console.log('cookie:is_vip', is_vip);
            this.$cookie.set('is_vip', is_vip);
          }
        }
      }
    },
    toBuy() {
      let jumpUrl = process.env.VUE_APP_CENTER_URL;
      window.location.href = jumpUrl;
    },  
    toLogin() {
      let jumpUrl = process.env.VUE_APP_LOGIN_URL;
      let redirectUrl = process.env.VUE_APP_MANAGE_URL + '#' + this.$route.fullPath;
      let az = Base64.encode(redirectUrl);
      window.location.href = jumpUrl + '?redirectUrl=' + az;
    },
    spxq(e) {
      if(!(this.is_vip)) {
        this.show_vip = true;
        return;
      }
      const routeData = this.$router.resolve({ path: '/productid', query: { id: e } }).href;
      console.log('routeData', routeData)
      window.open(routeData, '_blank');
    },
    shopid(e) {
      if(!(this.is_vip)) {
        this.show_vip = true;
        return;
      }
      const routeData = this.$router.resolve({ path: '/shopdeild', query: { id: e } }).href;
      console.log('routeData', routeData)
      //   const url = baseUrl + '/productid?id='+ e;
      //   // 打开新窗口
      window.open(routeData, '_blank');
    },
    async toCollectProduct() {
      if(this.multipleSelection.length == 0) {
        this.$message.error('请选择要取消的收藏的商品')
        return;
      }
      let ids = [];
      this.multipleSelection.map(v => {
        ids.push(v.id);
      });
      let param = {};
      param['type'] = 1;
      param['productIds'] = ids;
      const { data: res } = await collectProduct(param);
      if(res.code == '0000') {
        this.init();
      }
    },
    async toCollectShop() {

      if(this.multipleSelection.length == 0) {
        this.$message.error('请选择要取消的收藏的店铺')
        return;
      }
      let ids = [];
      this.multipleSelection.map(v => {
        ids.push(v.id);
      });
      let param = {};
      param['type'] = 1;
      param['shopIds'] = ids;
      this.loading = true;
      const { data: res } = await collectShop(param);
      this.loading = false;
      if(res.code == '0000') {
        this.init();
      }
    },
    cancelCollect() {
      if(this.type == 1) {
        this.toCollectShop();
      } else {
        this.toCollectProduct();
      }
    },
    cancelCollectShop(row) {
      this.multipleSelection = [row];
      this.cancelCollect();
    },
    cancelCollectProduct(row) {
      this.multipleSelection = [row];
      this.cancelCollect();
    },
    // 滚动条滚动
    handleScroll(event) {
      const currentScrollPosition = event.target.scrollTop || document.body.scrollTop || document.documentElement.scrollTop;
      //   console.log('handleScroll', event.target.scrollTop, document.body.scrollTop, document.documentElement.scrollTop)
      // 根据当前滚动位置更新锚点位置信息
      if(this.stickyFlag == false) {
        if(currentScrollPosition < 330) {
          this.$emit('sticky', {
            showSticky: false,
          })
          this.stickyFlag = false;
        } else {
          this.$emit('sticky', {
            showSticky: true,
          })
          this.stickyFlag = true;
        }
      } else {
        if(currentScrollPosition < 270) {
          this.$emit('sticky', {
            showSticky: false,
          })
          this.stickyFlag = false;
        }
      }
    },
    sortByDate(e) {
      console.log(123, e.order);
      this.searchForm = {
        ...this.searchForm,
        field: e.prop,
      };
      if(e.order == 'ascending') {
        this.searchForm = {
          ...this.searchForm,
          sort: 'asc',
        };
      } else if(e.order == 'descending') {
        this.searchForm = {
          ...this.searchForm,
          sort: 'desc',
        };
        this.sort = 'desc'
      } else {
        this.searchForm = {
          ...this.searchForm,
          sort: 'desc',
          field: '',
        };
      }
      this.init();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    init() {
      this.tableData = [];
      this.searchForm = {
        name: '',
        page: 1,
        pageSize: 20,
        field: '',
        sort: '',
      };
      this.multipleSelection = [];
      this.total = 0;
      this.$nextTick(() => {
        this.onSearch();
      })
    },
    onSearch() {
      if(this.type == 1) {
        this.getShopList();
      } else {
        this.getCollect();
      }
    },
    //获取收藏的商品
    async getCollect() {
      this.loading = true;
      let param = Object.assign({}, this.searchForm)
      const { data: res } = await getCollectProduct(param);
      this.loading = false;
      console.log('data', res.data);
      if(res.code == '0000') {
        this.tableData = res.data.records;
        this.loading = false;
        let aa = []
        res.data.records.forEach(z => {
          z.secondCategory = z.secondCategory.split(',')
          aa.push(z)
        })
        this.tableData = aa;
        this.tableData.forEach((z, i) => {
          this.toagetSaleAmtAndSumTrend(z, i)
        })
        this.total = res.data.total;
        this.curPage = res.data.current;
        this.pageSize = res.data.size;

      }
    },
    async toagetSaleAmtAndSumTrend(data, index) {
      let param = {};
      param["dateType"] = 1
      // param["id"] =this.$route.query.id;
      param["goodsId"] = data.goodsId;
      const { data: res } = await agetSaleAmtAndSumTrend(param);
      //   this.agetRelateFlowList(this.date_type)
      //   this.getStatisticDate(2)
      let dataList = res.data || []
      this.$set(this.tableData, index, {
        ...this.tableData[index],
        goodsTrends: dataList,
      })
      console.log('toagetSaleAmtAndSumTrend', this.tableData[index]);
    },
    //获取收藏的店铺
    async getShopList() {
      let param = Object.assign({}, this.searchForm)
      const { data: res } = await listCollectShop(param);
      console.log('data', res.data);
      if(res.code == '0000') {
        const { records, total } = res.data;
        this.tableData = records;
        this.total = total
      }
    },
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        this.type = query.type && query.type > 0 ? query.type : 1;
        if(this.type == 1) {
          this.pagetitle = '收藏的店铺'
        } else {
          this.pagetitle = '收藏的商品'
        }
        this.init();
      },
      immediate: true,
    },
  },

}
</script>

<!-- 防止组件冲突 -->
<style lang="less" scoped>
.abc {
  /deep/.el-table__body tr.current-row > td {
    color: #333;
    background: #4974e91f !important;
  }
}
/deep/ .dialogwidth {
  min-width: 300px;
}
.collect {
  width: calc(100% - 154px);
  min-height: 100vh;
  background-color: #fff;
  padding: 17px;
  &-top {
    background: #fff;
  }
  &-title {
    display: flex;
    align-items: center;
    &-color {
      display: inline-block;
      width: 4px;
      background-color: #4975e9;
      height: 18px;
      margin-right: 5px;
    }
    &-page {
      font-weight: bold;
      //   font-size: 20px;
      line-height: 23px;
      font-family: PingFang SC-Bold;
      color: #333;
    }
  }
  &-bottom {
    margin-top: 16px;
    &-search {
      /deep/.el-form-item__label {
        text-align: left;
      }
      &-icon {
        cursor: pointer;
      }
      &-btn {
        background: rgba(73, 117, 233, 0.08);
        border: 1px solid rgba(73, 117, 233, 0.5);
        color: #4975e9;
        font-size: 16px;
        padding: 7px 10px;
      }
    }
    &-table {
      /deep/.el-table__header th,
      .el-table__header tr {
        padding: 4px 0;
      }
      &-btn {
        padding: 8px 14px;
        font-size: 14px;
        color: #ffffff;
        background: #4975e9;
      }
      &-sticky {
        /deep/.el-table__header-wrapper {
          position: fixed;
          top: 0;
          z-index: 999;
        }
      }
    }
  }
}
</style>
